import { CircularProgressbarWithChildren, buildStyles, imagem } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const BioCard = ({minute, second, percentage, isRunning, email, imagem}) => {
    return(
        <>
        <div className="card">
            <div className="card-body text-muted">
                <small><i className="fas fa-user-unlock"></i> BIOMETRIA</small>
            </div>
            <div className="card-body">
            
            <CircularProgressbarWithChildren 
                className="p-5"
                value={percentage} 
                // text={`${percentage}%`} 
                styles={buildStyles({                                            
                    rotation: 0.0,
                    strokeLinecap: 'round',
                    textSize: '16px',
                    pathTransitionDuration: 0.5,
                    pathColor: `#ffb020`,
                    // pathColor: `rgba(144, 224, 0, ${percentage / 10})`,
                    textColor: '#f88',
                    trailColor: '#ddd',
                    backgroundColor: '#3e98c7',
                })}
                >
                
                {imagem
                    ? <img className="avatar" src={'data:image/jpeg;base64,'+imagem} width="128" height="auto" alt="foto de perfil" />
                    : <img src="https://md.uninta.edu.br/ecossistema/images/admin.png" width="128" alt="icone do palco virtual" />                                
                }
                </CircularProgressbarWithChildren>
                {!isRunning
                ?<h1>{minute}:{second}</h1>
                :<h1>{isRunning}</h1>
                }
                
                <>{percentage === 0 
                    ? 'Usuário não identificado' 
                    :<>
                        <b>{email} foi identificado!</b>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>Score</td>
                                    <td>{parseFloat(percentage).toFixed(2)}XP</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                }</>
            </div>           
        </div>
        </>
    )
}

export default BioCard 