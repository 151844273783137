import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { ToastProvider } from 'react-toast-notifications';
import ReactGa from 'react-ga4';

ReactGa.initialize("G-8LHQ9J7HJH")


ReactDOM.render(
  <React.StrictMode>
    <ToastProvider>
      <App />
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root')
);