import React, { createContext } from 'react'
import useAuth from './hooks/useAuth'

const Context = createContext()

function AuthProvider({children}) {
    const {loading, username, nome, password, setPassword, email, authenticated, setUsername, handleLogin, handleLogout, message} = useAuth()
    return(
        <Context.Provider value={{loading, username, nome, password, setPassword, email, authenticated, setUsername, handleLogin, handleLogout , message}}>
            {children}
        </Context.Provider>
    )
}

export { Context, AuthProvider}