import axios from 'axios'

export default axios.create({
    // baseURL: 'https://api.santosworkers.com/',    
    baseURL: 'https://experiments.uninta.edu.br/',    
    // baseURL: 'http://localhost:3001',    
    headers: {
        'x-access-token' : sessionStorage.getItem('token')
    }
    
})