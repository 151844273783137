import baixo from '../assets/baixo.png'
import cima from '../assets/cima.png'
import esquerda from '../assets/esquerda.png'
import direita from '../assets/direita.png'
import frente from '../assets/frente.png'

import vbaixo from '../assets/vbaixo.png'
import vcima from '../assets/vcima.png'
import vesquerda from '../assets/vesquerda.png'
import vdireita from '../assets/vdireita.png'
import vfrente from '../assets/vfrente.png'
import full from '../assets/full.png'

import { useEffect, useState, useRef, useCallback } from "react"
import api from '../api'
// import {Context} from '../Context/AuthContext'


// Componentes da dashboard 
import Webcam from "react-webcam";



import Heading from '../components/Heading'

import { useToasts } from 'react-toast-notifications';
import GenerateReport from '../functions/Analytics'

export default function Biometria() {
    useEffect(()=>{
        GenerateReport("Palco Virtual - Biometria");
    });
    const { addToast } = useToasts();
    const imagem = localStorage.getItem('imagem')
    const nome = sessionStorage.getItem('nome')
    const [images, setImages] = useState([])
    const [imgSrc, setImgSrc] = useState(null)

    const [info, setInfo] = useState([])
    // const { handleLogout } = useContext(Context)
    const username = sessionStorage.getItem('username')
    useEffect(()=>{
        (async ()=>{
            const {data} = await api.get(`/media/folders/${username}`)          
            console.log(data.data.Contents)  
            setInfo(data.data.Contents)  
            setImages(data.dados)
        })()
    }, [username])
    
    const webcamRef = useRef(null);


    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
    };

    const updateImage = (imgSrc) => {
            api.post('/media/sends3', {
                ra: username,
                img: imgSrc
            }).then(async(res)=>{
                if(res.data.success) {
                    addToast('A captura da imagem e o envio para o servidor foi realizado com sucesso!', { appearance: 'success' , autoDismiss: true});   
                    const {data} = await api.get(`/media/folders/${username}`)          
                    console.log(data.data.Contents)                      
                    setInfo(data.data.Contents)  
                    setImages(data.dados)                 
                    // alert('Imagem enviada com sucesso!')                    
                }
            })
    }

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImgSrc(imageSrc);
            updateImage(imageSrc)
        },
        [webcamRef, setImgSrc]
    );
    
    const handleDelete = (key) => {
        api.get('/media/deletes3/' + key.split('/').join('@')).then(async(res)=>{
            addToast("A imagem foi removida com sucesso", {appearance: "success", autoDismiss: true})
            const {data} = await api.get(`/media/folders/${username}`)          
            console.log(data.data.Contents)  
            setInfo(data.data.Contents)  
            setImages(data.dados)
        })
    }

    const [pictures, setPictures] = useState([
        frente, esquerda, direita, cima, baixo
    ])
    const [validate, setValidate] = useState([
        vfrente, vesquerda, vdireita, vcima, vbaixo
    ])
    const [etiquetas, setEtiquetas] = useState([
        'Olhando para a frente',
        'Olhando para a esquerda',
        'Olhando para a direita',
        'Olhando para cima',
        'Olhando para baixo'        
    ])
    return(
    <>        
        <Heading />
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12">
                    <a className="btn btn-light mb-3" href="/">
                        <i className="fas fa-arrow-left"></i> Voltar ao dashboard
                    </a>                                            
                    <h3> Biometria de {nome} </h3>
                    <p> <i className="fas fa-link text-muted"></i> Realize o registro fotográfico de 5 imagens clicando em "capturar imagem" para iniciar a sua identificação facial. </p>                                  
                </div>
                <div className="col-md-6 mt-3">
                    <h3>Referências</h3>
                    <p>Atenção! Realize as capturas com o rosto nas posições assinaladas nas figuras abaixo:</p>
                    <ul className="samples">                        
                        {
                            validate.map((item, key)=>{                                
                                if(key < info.length) { 
                                    return (
                                    <li align="center">
                                        <img src={item} width="100%" height="auto" alt=""/>
                                        <small>{etiquetas[key]}</small>
                                    </li>
                                    )
                                } else {
                                    return(
                                        <li align="center">
                                            <img src={pictures[key]} width="100%" height="auto" alt=""/>
                                            <small>{etiquetas[key]}</small>
                                        </li> 
                                    )                              
                                }
                            })
                        }
                        {info.length > 4 
                        ?<li align="center">
                            <img src={full} width="90%" height="auto" alt=""/>
                            <small>Cadastro Concluído</small>
                        </li>
                        : 'Ainda em aberto'
                        }
                    </ul>
                </div>
                <div className="col-md-6 mt-3">

                    <Webcam
                        audio={false}
                        height="auto"
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        videoConstraints={videoConstraints}
                    />
                    <div className="d-grid">
                        {info.length >= 10
                        ? <button disabled className="btn btn-lg btn-danger" onClick={capture}>Capturar imagem</button>          
                        : <button className="btn btn-lg btn-danger" onClick={capture}>Capturar imagem</button>          
                        }
                    </div>
                </div>
                {imgSrc && (
                    <div className="col-md-6">
                        <img src={imgSrc} alt=""/>
                    </div>
                )}
                {/* <div className="col-md-6 mb-3">
                    <div className="row">
                        {images.map((image)=>(
                            <div className="col-md-4 mb-3">
                                <img src={image} key={image} width="100%" height="auto" alt="" />
                                <div className="d-grid gap-2">
                                    <button className="btn btn-danger" onClick={()=>{console.log(image)}}>Remover</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
                {/* Setting up key and images  */}
                <div className="col-md-12 mt-5 mb-3">
                    <h3>Minha biometria</h3>
                    <p> <i className="fas fa-link text-muted"></i> Encontre abaixo imagens adicionadas à plataforma </p>                       
                    <div className="row mt-5">                        
                        {info.map((image)=>(
                            <div className="col-md-4 mb-3">                                
                                <img src={image.url} key={image.Key} style={{borderRadius: "12px", border: "solid 4px #ddd" }} width="100%" height="auto" alt="" />
                                <div className="d-grid gap-2">
                                    {info.length <= 5 
                                    ? <button disabled className="btn  btn-light mt-3" onClick={()=>{handleDelete(image.Key)}}>Remover</button>                                    
                                    : <button className="btn  btn-light mt-3" onClick={()=>{handleDelete(image.Key)}}>Remover</button>                                    
                                    }
                                    <div>
                                    {imagem
                                        ? <><img className="avatar" src={'data:image/jpeg;base64,'+imagem} width="36" height="auto" alt="foto de perfil" /> {username} <span className="homie">VALIDADA</span> </>
                                        : <><img src="https://md.uninta.edu.br/ecossistema/images/admin.png" width="36" alt="icone do palco virtual" /> {username} <span className="homie">VALIDADA</span> </>                               
                                    }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>        
    </>
    )
}