import RecordWebcam from '../components/RecordV2Video'
import Heading from '../components/Heading'
import { useEffect } from 'react';
import GenerateReport from '../functions/Analytics';

export default function Stage() {
    useEffect(()=>{
        GenerateReport("Palco Virtual - Apresentação");
    });
    return(
    <>
        <Heading />        
        <div className="container">               
            <div className="row alig-items-stretch">               
                <div className="col-md-12 mb-3">
                                        
                    <a className="btn btn-light mb-3" href="/">
                        <i className="fas fa-arrow-left"></i> Voltar ao dashboard
                    </a>      
                    <h3>Palco virtual</h3>
                    <p> <i className="fas fa-link text-muted"></i> Utilize a ferramenta abaixo para realizar a sua apresentação</p>
                    <RecordWebcam />
                </div>               
            </div>        
        </div>        
    </>
    )
}