import {useContext} from 'react'
import {Context} from '../Context/AuthContext'

function Heading () { 
    const { handleLogout } = useContext(Context)
    const username = sessionStorage.getItem('username')
    const imagem = localStorage.getItem('imagem')
    return(
        <>       
        <header className="p-3 mb-3 shadow-sm">
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
                    <span>AIAMIS</span>
                </a>

                <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                    {/* <li><a href="/" className="nav-link px-2 link-secondary">Dashboard</a></li>                   */}
                </ul>

                    {/* <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                        <input type="search" className="form-control" placeholder="Search..." />
                    </form> */}

                {/* <div className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3 text-muted">
                    { new Date().toLocaleString()}
                </div> */}
                {username
                    ?<div className="dropdown text-end">
                        <a href="/" className="d-block btn btn-sm btn-light link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">                            
                            {imagem
                                ? <><img className="avatar" src={'data:image/jpeg;base64,'+imagem} width="36" height="auto" alt="imagem" /> {username} </>
                                : <><img src="https://md.uninta.edu.br/ecossistema/images/admin.png" width="36" alt="icone do palco virtual" /> {username} </>                               
                            }
                        </a>
                        <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">                        
                        <li><a className="dropdown-item" href="/">Meu perfil</a></li>                    
                        <li><button className="btn dropdown-item" onClick={handleLogout}>Sair</button></li>
                        </ul>
                    </div>                    
                    : <></>        
                }                    
                </div>
            </div>
        </header>
        </>
    )
}

export default Heading