import { useEffect, useState } from 'react'
import api from '../api'
import Heading from '../components/Heading'
// import Cronometer from '../components/Cronometer'
// import axios from 'axios'
import { useToasts } from 'react-toast-notifications';
import GenerateReport from '../functions/Analytics';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Dashboard () { 

    useEffect(()=>{        
        GenerateReport("Palco Virtual - Dashboard");
    },[]);

    const { addToast } = useToasts();
    const [bio, setBio] = useState([]);
    const [records, setRecords] = useState(null)
    const [events, setEvents] = useState(null)
    const username = sessionStorage.getItem("username")
    const nome = sessionStorage.getItem("nome")
    const [presentation, setPresentation] = useState('')
    const imagem = localStorage.getItem('imagem')
    
    var [videoEmbedCode, setVideoEmbedCode] = useState(null)
    var [videoEmbedCodeLoading, setVideoEmbedCodeLoading] = useState(true)
    async function getVideo(uri) {   
        await api.get(`/vimeo/video/${uri}/embed`).then((data) => {
            setVideoEmbedCodeLoading(false)
            setVideoEmbedCode(data.data)
        }).catch(() => {
            addToast(`Não foi possível carregar o vídeo!`, {appearance: "info"})
        })
        // const embed = await api.get(`http://localhost:8081/vimeo/video/${id}/embed`)
    }
    
    const carregaEventos = async ()=> {            
        document.location.reload()
    }
    useEffect(()=>{
        (async ()=>{            
            await api.get(`/media/folders/${username}`).then((data)=>{
                // console.log(data.data.dados)
                setBio(data.data.dados)
                // console.log(bio)
            })          
            await api.get(`/media/events/${username}`)
                .then((data)=>{
                // console.log(data.data.events)
                setEvents(data.data.events)                
                setRecords(data.data.events[0].uri)        
            }).catch((err)=>{
                console.log(err)
            })
        })()
    }, [username, records,setRecords, setEvents])

    const updatePresentation = (key) => {
        setPresentation(key)
        // console.log(presentation)
    }

    const copyUrl = (value) => {
        navigator.clipboard.writeText(value)
        addToast(`O código de submissão do evento ${value} foi copiado com sucesso!`, {appearance: "info"})
    }
    
    return(
        <>
            <Heading />
            <div className="container mt-5">
                {/* <Cronometer /> */}
                <div className="row">
                    {/* <div className="col-md-2">                
                        <img src="https://md.uninta.edu.br/ecossistema/images/admin.png" width="150" alt="" />
                    </div>             */}
                    <div className="col-md-10">
                        <h3>Dashboard</h3>
                        <p> <i className="fas fa-link text-muted"></i> Utilize os atalhos abaixo para navegar pelo Palco Virtual</p>                       
                        {/* <button className="btn shadow-lg btn-light mb-3">Informar um problema</button>                          */}
                    </div>                                   

                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body text-muted">
                                <small><i className="fas fa-user-check"></i> BIOMETRIA</small>
                            </div>
                            <div className="card-body" align="center">
                                {/* <img src="https://md.uninta.edu.br/ecossistema/images/admin.png" width="150" alt="" /> */}
                                {imagem
                                    ? <img className="avatar" src={'data:image/jpeg;base64,'+imagem} width="128" height="auto" alt="foto de perfil" />
                                    : <img src="https://md.uninta.edu.br/ecossistema/images/admin.png" width="128" alt="icone do palco virtual" />                                
                                }
                                {bio.length === 0
                                ?<>
                                    <h3>Cadastrar biometria</h3>
                                    <p>
                                    Olá <b className="nome">{nome}</b>! Se esse é o seu primeiro acesso Clique no botão abaixo para realizar o cadastro da sua biometria.
                                    </p>
                                </>
                                :<>                                
                                    <h3> <i className="fa fa-check-circle text-success"></i> Biometria cadastrada</h3>
                                    <p>
                                        Olá <b className="nome">{nome}</b>! Você já pode prosseguir para o Palco Virtual. Caso queira rever suas fotos clique em acessar.
                                    </p>
                                </>
                                }                                
                            </div>
                            <div className="card-footer d-grid">
                                <a className="btn btn-warning" href="/stage">Acessar</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body text-muted">
                                <small><i className="fas fa-video"></i>  VIDEO</small>
                            </div>
                            <div className="card-body" align="center">
                                <img src="https://md.uninta.edu.br/ecossistema/images/admin.png" width="128" alt="" />
                                <h3>Palco Virtual</h3>                                
                                <p>Caso você não possua nenhum evento finalizado clique no botão abaixo para avançar para a apresentação</p>                                                                                                            
                            </div>
                            <div className="card-footer d-grid">                                
                                <a className="btn btn-danger" href="/video">Acessar</a>                                                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-5 mb-3">
                        <h3>Meus eventos</h3>
                        <p> <i className="fas fa-link text-muted"></i> Encontre abaixo eventos finalizados e o código de submissão para a atividade</p>
                    </div>
                    
                    

                    {events
                        ? <div className="table-responsive"> 
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        {/* <th>Aluno(a) identificado?</th> */}
                                        {/* <th>Acerto %</th>                                         */}
                                        <th>Pronto para envio?</th>
                                        <th>Reproduzir</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {events.map((event)=>{
                                        return (
                                        <tr key={event.uri}>
                                            <td><button className="btn" onClick={(e)=>{copyUrl(event.uri)}}><i className="far fa-copy text-muted"></i> {event.uri}</button></td>
                                            {/* <td>{event.recognize? 'Identificou corretamente': 'Não identificou corretamente'}</td> */}
                                            {/* <td>{event.recognize? <><i className="fas fa-check-circle text-muted"></i> Identificou corretamente </> : <><i className="fas fa-exclamation-triangle text-warning"></i> Não identificou corretamente</>}</td> */}
                                            {/* <td>{parseFloat(event.success/60).toFixed(2)}%</td>                                                */}
                                            <td><i className="fas fa-check-circle text-success"></i> Sim</td>                                         
                                            {/* <td>{((event.success + event.failure) * 100 / (event.success + event.failure)) }</td> */}
                                            <td><button className="btn" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=>{
                                                updatePresentation(event.uri)
                                                getVideo(event.uri)
                                            }}><i className="fa fa-play text-muted"></i></button></td>
                                        </tr>
                                    )})}
                                </tbody>
                            </table>
                            <div className="modal fade" id="exampleModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg modal-dialog-centered">                            
                                    <div className="modal-content col-md-12 mt-5 mb-5">
                                        <div className="card">
                                            <div className="modal-header d-flex text-muted">                                        
                                                <small><i className="fas fa-projector"></i>  EVENTO FINALIZADO </small>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" 
                                                    onClick={() => {
                                                        setVideoEmbedCodeLoading(true); 
                                                        setVideoEmbedCode(null) 
                                                    }}></button>
                                            </div>                                    
                                            <div className="card-body">
                                                <h3>Palco Virtual - {username}</h3>
                                                <p> <i className="fas fa-link text-muted"></i> Código do evento: {presentation}</p>                                                                
                                                {videoEmbedCodeLoading ? <div style={{ height: "280px",width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                    <CircularProgress /> 
                                                </div> : <>
                                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} dangerouslySetInnerHTML={{__html: videoEmbedCode}} />                                              
                                                    <script src="https://player.vimeo.com/api/player.js" />
                                                </>}
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                        :   <div className="col-md-12 mt-3 mb-5" align="center">       
                                <i className="fas fa-3x fa-sad-tear text-muted mb-3"></i> 
                                <p>Nenhum evento disponível ou visível!</p>
                                <button className="btn btn-light" onClick={carregaEventos}>Carregar meus eventos</button>
                            </div>
                    }
                </div>
            </div>                            
        </>
    )
}
