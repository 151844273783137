import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './pages/Login';
// import Users from './pages/Users';
import Stage from './pages/Stage';
import Biometria from './pages/Biometrics';

import { Context } from './Context/AuthContext'
import Dashboard from './pages/Dashboard';
import Review from './pages/Review';


function CustomRoute({isPrivate, ...rest}) {
    const { loading, authenticated } = useContext(Context)

    if(loading) {
        return(
            <h1>Loading</h1>
        )
    }

    if(isPrivate && !authenticated) {
        return <Redirect to="/login" />
    }

 

    return <Route { ...rest} />
}

export default function Routes() {
    return (
        <Switch>
            <CustomRoute isPrivate exact path="/" component={Dashboard} />      
            <CustomRoute exact path="/login" component={Login} />                  
            <CustomRoute exact path="/review" component={Review} />                  
            <CustomRoute isPrivate exact path="/video" component={Stage} />      
            <CustomRoute isPrivate exact path="/stage" component={Biometria} />                  
        </Switch>
    );
}