import {useState, useEffect} from 'react' 
import Heading from '../components/Heading'
import GenerateReport from '../functions/Analytics';

import api from '../../src/api'

function Review(){
    useEffect(()=>{
        GenerateReport("Palco Virtual - Revisão");
    });
    var [id, setId] = useState(null)
    var [url, setUrl] = useState(null)
    var [videoEmbedCode, setVideoEmbedCode] = useState(null)
    function getQuery(e){
        e.preventDefault()
        setUrl(id)
        // alert(`${id}`)
    }

    async function getVideo() {   
        const embed = await api.get(`/vimeo/video/${id}/embed`)
        // const embed = await api.get(`http://localhost:8081/vimeo/video/${id}/embed`)
        setVideoEmbedCode(embed.data)
    }
    return(
        <>
        <Heading />

        <div className="container">
            <div clasName ="row">                
                <div className="col-md-12 mt-5 mb-5">                
                    <center className="mb-5">
                        <h3 className="mb-3">Palco Virtual - Revisar apresentação</h3>
                        <p>Olá! Insira o código do vídeo no campo abaixo para que possamos carregar os dados da apresentação</p>
                    </center>
                    <form>
                        <div className="input-group">
                        <input className="form-control mb-3" placeholder="Insira o código do vídeo" onChange={(e)=>{setId(e.target.value)}}/>
                            <div className="input-group-append">
                                <button className="btn btn-danger" onClick={(e)=>{
                                    getQuery(e)
                                    getVideo()
                                }}>
                                    <i className="fa fa-search"></i> Pesquisar
                                </button>
                            </div>
                        </div>
                        <div clasName="col-md-12 col-sm-12 mt-5 mb-3">


                        <div dangerouslySetInnerHTML={{__html: videoEmbedCode}} />

                        {/* <iframe title="Seminário Interdisciplinar" src = {url ? `https://player.vimeo.com/video/${url}` : ""}  width="720" height="480" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen /> */}

                        <script src="https://player.vimeo.com/api/player.js" />
                        </div>
                    </form>            
                </div>
                <div className="col-md-6 mb-5">        
                        <h3>Critérios da avaliação do vídeo</h3>                
                        <table className="table">
                            <thead>
                                <th>Item avaliado</th>
                                <th>Nota máxima</th>
                            </thead> 
                        <tbody>                    
                            <tr>
                                <td> Postura no momento de apresentação.</td>
                                <td> 1,5 </td>
                            </tr>
                            <tr>                        
                                <td>Clareza na apresentação.</td>
                                <td>1,5</td>                    
                            </tr>
                            <tr>
                                <td>Domínio técnico sobre o assunto tratado (embasamento teórico) </td>
                                <td>2,0</td>
                            </tr>
                            <tr>
                                <td>Organização sequencial do assunto abordado na apresentação</td>
                                <td>1,5</td>
                            </tr>
                            <tr>
                                <td>Observação das recomendações para realização da atividade</td>
                                <td>1,5</td>                    
                            </tr> 
                            <tr>
                                <td>Relação entre a teoria da disciplina, visita técnica e vídeo</td>
                                <td>2,0</td>
                            </tr>                       
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
    )
}

export default Review  