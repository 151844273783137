import {     
    useRecordWebcam,    
    RecordWebcam,
    CAMERA_STATUS
} from 'react-record-webcam'

import api from '../api';

// importando faceapi 

import * as faceapi from 'face-api.js'

// importando hooks 

import { useRef, useEffect, useState } from 'react'
// import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';

import { useToasts } from 'react-toast-notifications';


// Import biocard 
import BioCard from './BioCard';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function RecordV2Video(props) {     
    
    const username = sessionStorage.getItem('username')

    const { addToast } = useToasts();

    var submitRef = useRef()

    var [minute, setMinute ] = useState(0)
    var [second, setSecond] = useState(0)
    var [time, setTime] = useState(8 * 60)
    const [isRunning, setIsRunning] = useState(undefined)

    
    const timer = () => {                        
            recordWebcam.start()
            if (recordWebcam.status !== "ERROR") {
                setInterval(()=>{                
                    setMinute(Math.floor(time/60) < 10 ? '0' + Math.floor(time/60)  : Math.floor(time/60)  )
                    setSecond(time % 60 < 10 ? '0' + time % 60 : time % 60 )
                    if(Math.floor(time/60) > 8 && time % 60 === 59){
                        addToast("A gravação foi iniciada com sucesso, boa apresentação!", {appearance: 'info', autoDismiss: true})
                    }
                    if(Math.floor(time/60) <= 5 && time % 60 === 55) {
                        addToast("Atenção, já se passaram cerca de 5 minutos!", {appearance: 'info', autoDismiss: true})
                    }
                    if(Math.floor(time/60) <= 2 && time % 60 === 55) {
                        addToast("Atenção, já se passaram cerca de 8 minutos!", {appearance: 'warning', autoDismiss: true})
                    }                
                    setTime(time--)           
                }, 1000)         
            } else {
                addToast("Não conseguimos iniciar a gravação, verifique se a camera está ativa e atualize a página para realizar uma nova tentativa!", {appearance: "warning", autoDismiss: true})
            }
    }

    const regravar = () => {
        // setTime(10*60)
        // recordWebcam.retake()
        window.location.reload()
    }

    const stopRuntimer = () => {   
        setIsRunning(`${minute}:${second}`)  
        addToast("Atenção, a gravação foi encerrada pelo usuário!", {appearance: "info"})
        recordWebcam.stop()
    }

    var [percentage, setPercentage] = useState(0);
    var [dialog, setDialog] = useState(false);
    var [envioErro, setEnvioErro] = useState(false);
    const imagem = localStorage.getItem('imagem')
    const nome = localStorage.getItem('nome')
    // WebcamRecord dependencies 
    const email = sessionStorage.getItem('username')    
    const recordWebcam = useRecordWebcam();

    var [reconheceu_usuario, setreconheceu_usuario] = useState(false)
    var [acerto, setAcerto] = useState(0)
    var [erro, setErro] = useState(0)

    const getRecordingFileHooks = async () => {
        setDialog(true);
        const blob = await recordWebcam.getRecording();      
        console.log(blob.type)
        console.log({blob})
        // var upload = window.URL.createObjectURL(blob)
        var upload = new File([blob], "upload", { type: blob.type ,lastModified: new Date().getTime()})
        console.log(upload)
        submitRef.current.setAttribute("disabled", "disabled")
        // addToast("Seu vídeo está sendo preparado para envio, aguarde a confirmação para prosseguir!", {appearance: "info"})
        const form = new FormData()
        form.append('upload', upload)
        form.append('email', sessionStorage.getItem('username'))
        api.post('/media/vimeo', form).then( res => { 
            console.log(res)
            console.log(res.data.resolved.uri)            
            api.post('/media/statistics',
            {
                user: email,
                uri: res.data.resolved.uri,
                reconheceu_usuario: reconheceu_usuario,
                taxa_acerto: acerto,
                taxa_erro: erro,
            }).then((res)=>{
                setDialog(false);
                addToast("O vídeo foi enviado com sucesso! Você pode retornar ao dashboard para recuperar o código da apresentação.", { appearance: 'success'});
                // alert("Vídeo enviado com sucesso! Você pode clicar para retornar ao dashboard para recuperar o código da apresentação.")
            }).catch(() => {
                setEnvioErro(true)
            });
        })
    };

    // FaceAPI dependencies         
    const [initializing, setInitializing] = useState(null)    
    const [labels, setLabels] = useState([])    
    const canvasRef = useRef()    

    useEffect(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
            .catch(() => {
                addToast("Ocorreu um erro ao carregar a aplicação, habilite seu microfone para prepararmos o ambiente do Palco Virtual ", {appearance: "error"})
            });
        } else {
            addToast("O navegador não suporta o ambiente do Palco Virtual ", {appearance: "error",  autoDismiss: true})
        }
    }, [])

    useEffect(()=>{
        (async ()=>{
            const {data} = await api.get(`/media/folders/${username}`)
            if (data.dados.length >=1) {
                setLabels(data.dados)
            } else {
                alert("Você ainda não realizou o cadastro de nenhuma biometria! Retorne ao dashboard e acesse a tela de cadastro de Biometria!")
                window.location.replace("/")
            }
        })()
    }, [])

    useEffect(()=>{
        const loadModels = async() => {
            const MODEL_URL = process.env.PUBLIC_URL + '/models'
            setInitializing(true)
            addToast("O Palco Virtual está sendo preparado! Dentro de instantes você poderá iniciar a gravação", {appearance:"info", autoDismiss: true})
            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL), // detectar rostos no video 
                faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL), // desenha traços no rosto 
                faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL), // reconhecimento
                faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL), // emoji 
                faceapi.nets.ageGenderNet.loadFromUri(MODEL_URL), // adivinhar idade 
                faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL) // 
            ]).then(()=>{
                recordWebcam.open()
            }).catch((e)=>{
                addToast("Ocorreu um erro ao carregar a aplicação, atualize a página para realizar uma nova tentativa", {appearance: "error"})
                window.location.replace("/")
            })//.catch((e)=>{window.location.replace("/")})
        }
        loadModels()
    }, [])
    // Handle labels and match faces 

    const loadLabels = () => {                
        var desc = []       

        return Promise.all(labels.map(async label => {            
            // console.log(label)
            const img = await faceapi.fetchImage(label)                
            const detections = await faceapi 
                .detectSingleFace(img)
                .withFaceLandmarks()
                .withFaceDescriptor()
            if(detections) { 
                desc.push(detections.descriptor)                 
            }            
            // console.log(desc)      
            return new faceapi.LabeledFaceDescriptors(email, desc)
        }))  
    }

    // Handle face detection on video 

    const handleVideo = async (WebcamRenderProps) => {
        // console.log('Salut! À biento')
        // console.log(recordWebcam.webcamRef.current)

        const labels = await loadLabels()
        // console.log(labels)
        
        canvasRef.current.innerHtml = faceapi.createCanvasFromMedia(recordWebcam.webcamRef.current)
        const displaySize = {
            width: 720,
            height: 560
        }
        faceapi.matchDimensions(canvasRef.current, displaySize)    

        addToast("A configuração do Palco Virtual foi finalizada com sucesso! Você já pode iniciar a apresentação.", {appearance:"success"})

        setInterval(async() => {
            if(initializing) { 
                setInitializing(false)
                // console.log('Ça va?')
            }
            
                    
            
            // console.log('Je vais bien, et toi?')  
            /**
             * O bloco abaixo faz uma verificação dentro da página para verificar se a referência existe e está correta, 
             * caso esteja incorreta ele redireciona o usuário para a tela inicial. Preciso adicionar uma mensagem de erro 
             * e algumas tratativas para fazer um dispatcher para o usuário. 
             */
            let detections = []
            try {
                // console.table({message : `Salut, Gabriel, Ça va?`})
                detections = await faceapi.detectAllFaces(
                    recordWebcam.webcamRef.current, 
                    new faceapi.TinyFaceDetectorOptions()
                ).withFaceLandmarks().withFaceExpressions().withFaceDescriptors()

            }  catch (e) {
                alert("Ocorreu um erro na aplicação, tente novamente em alguns instantes.")
                window.location.replace("/")
            }      

            
      

            // console.log(detections)

    

            const resizedDetections = faceapi.resizeResults(detections, displaySize)

            // face matcher - carregamento de imagens 
            const faceMatcher = new faceapi.FaceMatcher(labels, 0.5)

            canvasRef.current.getContext('2d').clearRect(0,0,720, 560)
            faceapi.draw.drawDetections(canvasRef.current, resizedDetections)
            faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections)
            // faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections)            
            
            const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor))
            results.forEach((result, index) => {                 
                const box = resizedDetections[index].detection.box                 
                const { label, distance } = result 
                new faceapi.draw.DrawTextField([
                    `${label} (${parseInt(distance * 100, 10)})`
                ], box.bottomRight).draw(canvasRef.current)                
                setreconheceu_usuario(true)
                if(distance < 0.3) {
                    setErro(erro++)
                } else { 
                    // console.log(acerto)
                    setAcerto(acerto++)
                    setPercentage((percentage++)/100)
                }
            })
            

        }, 100);
    }
   

    return (
        <>
            <RecordWebcam 
                render={( WebcamRenderProps)=>{
                    return(
                        <>
                        {envioErro ? 
                        <>
                            <div class="alert alert-danger" role="alert">
                                <div
                                    style={{fontSize: "30px"}}
                                >
                                    Falha no envio!
                                </div>
                                    Caso o código da sua apresentação não tenha sido gerado no processo automático de envio, favor clicar no botão de "DOWNLOAD" e envie o arquivo com o vídeo da sua apresentação para esse formulário: &nbsp;
                                <a
                                    target='_blank'
                                    href='https://forms.gle/WURQW8UWjco62QNu5' rel="noreferrer">
                                    https://forms.gle/WURQW8UWjco62QNu5. &nbsp;
                                </a>
                                Precisamos do arquivo com a apresentação para que seja avaliado pelos professores da disciplina. 
                                <button
                                        className="btn btn-dark"
                                        style={{marginTop: "15px", alignSelf: "flex-end"}}
                                        disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW}
                                        onClick={recordWebcam.download}
                                    >
                                        Download
                                </button>
                            </div>
                        </> : <></>}
                        <div className="row">                                  
                            <div className="col-md-12 mt-5">                             
                                {/* <p>{recordWebcam.status}</p> */}
                                
                                <div className="btn-group mb-3">
                                <button 
                                    className="btn btn-danger"
                                    disabled={
                                        recordWebcam.status === CAMERA_STATUS.OPEN || 
                                        recordWebcam.status === CAMERA_STATUS.RECORDING || 
                                        recordWebcam.status === CAMERA_STATUS.PREVIEW  
                                    }
                                    onClick={recordWebcam.open}
                                >Abrir a câmera</button>
                                <button 
                                    className="btn btn-outline-danger" 
                                    onClick={timer}
                                    disabled={
                                        recordWebcam.status === CAMERA_STATUS.CLOSED ||
                                        recordWebcam.status === CAMERA_STATUS.RECORDING ||
                                        recordWebcam.status === CAMERA_STATUS.PREVIEW
                                        }                                        
                                    >Iniciar gravação</button>                               
                                {/* <button
                                    className="btn btn-outline-danger"
                                    disabled={
                                        recordWebcam.status === CAMERA_STATUS.CLOSED ||
                                        recordWebcam.status === CAMERA_STATUS.PREVIEW
                                    }
                                    onClick={recordWebcam.close}
                                >
                                    Fechar a câmera
                                </button>                            */}
                                {/* <button
                                    className="btn btn-light"
                                    disabled={
                                    recordWebcam.status === CAMERA_STATUS.CLOSED ||
                                    recordWebcam.status === CAMERA_STATUS.RECORDING ||
                                    recordWebcam.status === CAMERA_STATUS.PREVIEW
                                    }
                                    onClick={recordWebcam.start}
                                >
                                    Iniciar gravação
                                </button> */}
                                <button
                                    className="btn btn-outline-danger"

                                    disabled={recordWebcam.status !== CAMERA_STATUS.RECORDING}
                                    onClick={stopRuntimer}
                                    // onClick={recordWebcam.stop}
                                >
                                    Encerrar gravação
                                </button>
                                <button
                                    className="btn btn-outline-danger"

                                    disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW}
                                    onClick={regravar}
                                >
                                    Refazer gravação
                                </button>
                                {/* <button
                                    className="btn btn-outline-danger"

                                    disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW}
                                    onClick={recordWebcam.download}
                                >
                                    Download
                                </button> */}
                                <button
                                    ref = {submitRef}
                                    className="btn btn-outline-danger"
                                    disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW}
                                    onClick={getRecordingFileHooks}
                                >
                                    Enviar apresentação
                                </button>                            
                            </div>     
                            <div className="row">
                            <div className="col-md-4" align="center">
                                <BioCard 
                                    percentage={percentage}
                                    imagem={imagem}
                                    minute={minute}
                                    second={second}
                                    isRunning={isRunning}
                                    email={email}
                                />
                            </div>
                            <div className="col-md-6">
                                <video
                                    ref={recordWebcam.webcamRef}
                                    id="video"
                                    style={{
                                        display: `${
                                        recordWebcam.status === CAMERA_STATUS.OPEN ||
                                        recordWebcam.status === CAMERA_STATUS.RECORDING
                                            ? "block"
                                            : "none"
                                        }`
                                    }}
                                    autoPlay
                                    muted
                                    onPlay={handleVideo}                                    
                                    width="720"
                                    height="560"
                                />      
                                
                                <canvas 
                                    ref={canvasRef} 
                                    style={{
                                        display: "none"  
                                    }}                   
                                    // style={{
                                    //     display: `${
                                    //     recordWebcam.status === CAMERA_STATUS.OPEN ||
                                    //     recordWebcam.status === CAMERA_STATUS.RECORDING
                                    //         ? "block"
                                    //         : "none"
                                    //     }`
                                    // }}
                                />
                                
                                <video                                
                                    ref={recordWebcam.previewRef}
                                    style={{
                                        display: `${
                                        recordWebcam.status === CAMERA_STATUS.PREVIEW ? "block" : "none"
                                        }`
                                    }}
                                    autoPlay
                                    controls
                                    loop
                                />
                            </div>  
                            <div className="col-md-12 mt-5 mb-5">
                                <h3>Minha biometria</h3>
                                <p> <i className="fa fa-link"></i> Imagens enviada à plataforma para serem utilizadas para biometria</p>
                            </div>
                            {labels.map((item)=>{
                                return(
                                    <div className="col-md-4 mb-3">
                                        <img src={item} width="100%" height="auto" alt="" style={{borderRadius: "12px", border: "solid 4px #ddd" }}/>
                                        <div className="mt-3">                                            
                                            {imagem
                                                ? <><img className="avatar" src={'data:image/jpeg;base64,'+imagem} width="36" height="auto" alt="foto de perfil" /> {username} <span className="homie">VALIDADA</span> </>
                                                : <><img src="https://md.uninta.edu.br/ecossistema/images/admin.png" width="36" alt="icone do palco virtual" /> {username} <span className="homie">VALIDADA</span> </>                               
                                            }
                                        </div>
                                    </div>
                                )
                            })}                               
                        </div>                            
                            </div>
                        </div>
                        </>
                    )
                }}
            />
            <Dialog
                open={dialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box 
                    style={{height: "180px"}}
                >
                <DialogTitle id="alert-dialog-title">{"Seu vídeo está sendo preparado para envio, aguarde para prosseguir!"}</DialogTitle>
                <DialogContent>
                    <LinearProgress />
                </DialogContent>
                </Box>
            </Dialog>
        </>        
    )
}