import { useState, useEffect } from 'react'

import api from '../../api'
import history from '../../history'

import { useToasts } from 'react-toast-notifications';


export default function useAuth() {
    const { addToast } = useToasts();

    const [authenticated, setAuthenticated] = useState(false)
    const [loading, setLoading ] = useState(true)
    const [username, setUsername ] = useState('')
    const [password, setPassword ] = useState('')
    const [nome, setNome ] = useState('')
    const [email, setEmail] = useState('')
    // const [token, setToken] = useState(null)
    const [message, setMessage] = useState('')

    useEffect(()=>{
        const token = sessionStorage.getItem('token')        
        if(token) {
            api.defaults.headers.Authorization = `x-access-token: ${token}`
            setAuthenticated(true)
        }
        setLoading(false)
    }, [])

    async function handleLogin(event) {
        event.preventDefault()     
        setMessage('Aguarde...')     
        addToast('Estamos verificando suas informações, logo você será redirecionado', { appearance: 'info', autoDismiss: true });
        await api.post('/auth/loginv2',{
            username: username,
            password: password
        }).then((data)=>{
        const { data: {token, user, email, imagem} } = data
        // setToken(token)
        sessionStorage.setItem('token', token)
        // localStorage.setItem('token', token)
        localStorage.setItem('imagem', imagem)
        sessionStorage.setItem('username', username)                     
        sessionStorage.setItem('nome', user.nome.split(' ')[0])  
        setNome(nome)
        setEmail(email)   
        addToast("Autenticação realizada com sucesso! Seja bem vindo " + user.nome, { appearance: 'success', autoDismiss: true });
        api.defaults.headers.Authorization = `x-access-token: ${token}`
        setAuthenticated(true)
        history.go('/')        
        }).catch((e)=>{
            addToast("Atenção, o RA ou senha informada é inválida!" , { appearance: "error", autoDismiss: true})
        })
    }

    function handleLogout() {
        setAuthenticated(false)
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('username')    
        api.defaults.headers.Authorization = undefined
        history.go('/login')
        // history.replace(window.location)
    }
    return { authenticated, loading, nome, email, username, setUsername, password, setPassword, message, handleLogin, handleLogout}
}