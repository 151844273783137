// import { createContext, useContext } from 'react'
import { Redirect } from 'react-router-dom';
import { useContext, useEffect } from 'react'

import Heading from '../components/Heading'

// const Context = createContext()
import {Context} from '../Context/AuthContext'

// Use Toast 
import { useToasts } from 'react-toast-notifications';
import GenerateReport from '../functions/Analytics';

// import TextField from '@material-ui/core/TextField';

function Login() {

  useEffect(()=>{        
    GenerateReport("Palco Virtual - Login");
  },[]);

  const { addToast } = useToasts();
  const { authenticated, handleLogin } = useContext(Context)  
  const {username, setUsername} = useContext(Context)  
  const {password, setPassword} = useContext(Context)  
  const { message } = useContext(Context)

  if (authenticated) {
    return <Redirect to="/" />;
  }
  // window.location.reload()
  return (    
    <div className="App">
      <Heading />
      <header className="container p-2 mt-5">

        <div className="row d-flex justify-content-center">
          {/* <div className="col-md-6 h-500 p-5 text-white bg-warning panel" style={{height: "700px", borderRadius: "40px"}}>

          </div> */}
          {/* <div className="col-md-12 mt-3 mb-5" align="center">       
            <i className="fad fa-3x fa-smile text-muted mb-3"></i> 
              <p>Já possui uma conta na plataforma?</p>
              <button className="btn btn-light" onClick={()=>{console.log("Hello")}}>Fazer autenticação</button>
          </div> */}
          <div className="col-md-6">
            <center>
              <h1>Palco Virtual</h1>
              <p>Para continuar faça login</p>       
              {message}             
            </center>

          <form>
            <label className="text-muted mb-1"><b>Registro acadêmico:</b></label>
            <input 
              className="form-control mb-3" 
              type="text"  
              name="username" 
              placeholder="RA/Nome de usuário"
              value={username} 
              onChange={(e) => setUsername(e.target.value)} />  
            <label className="text-muted mb-1"><b>Senha:</b></label>
            <input 
              className="form-control mb-3" 
              type="password"  
              name="password" 
              placeholder="Senha"
              value={password} 
              onChange={(e) => setPassword(e.target.value)} />               
            <p className="text-muted">
              <a className="text-dark mb-3" href="https://virtual.uninta.edu.br/login.html?errorcode=4#">Esqueceu sua senha?</a>
              <br />
              <br />             
            </p>
            <div className="d-grid gap-2 mb-3 mt-3">
              <button onClick={handleLogin} className="btn btn-warning btn-block">Entrar</button>
            </div>
            <span className="text-muted mt-5">
              <small>
                2021. Centro Universitário Inta. UNINTA. <br /><br />

                Diretoria de Inovação Educacional - PROEAD.
              </small>
            </span>
          </form>
          </div>
        </div>        
      </header>
    </div>
  );
}

export default Login
